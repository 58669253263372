import { useLocalization, useRouter } from '@akinon/next/hooks';
import { Icon } from '@theme/components';
import { ROUTES } from '@theme/routes';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Search from '.';
import { usePathname, useSearchParams } from 'next/navigation';
import { swanSearch } from '@theme/utils/swan';
import { useGetClientWidgetDataHandler } from '@theme/hooks';
import fetchSearchRedirectionResult from './samanlik-client';

export default function SearchBar() {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [pathChanged, setPageChanged] = useState({
    changed: false,
    pathname: pathname
  });

  const { t } = useLocalization();
  const { data, isSuccess } = useGetClientWidgetDataHandler({
    slug: 'search-items'
  });

  const placeholders = data?.attributes?.placeholder_items;
  const trendings = data?.attributes?.trend_items;

  useEffect(() => {
    const searchPage = searchParams.get('search_text');

    if (pathname !== pathChanged.pathname) {
      setPageChanged({ changed: true, pathname: pathname });

      if (!searchPage && pathChanged) {
        setSearchText('');
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (placeholders && placeholders?.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === placeholders?.length - 1 ? 0 : prevIndex + 1
        );
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [placeholders]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && searchText.trim() !== '') {
      handleSubmit();
    }
  };

  const handleSearchTextCleaned = async () => {
    const inputElement = document.getElementById('header-nav-search');
    inputElement.blur();
  };

  const handleSubmit = () => {
    do_search(searchText);
  };

  const do_search = async (searchQuery: string) => {
    const url = `${ROUTES.LIST}/?search_text=${searchQuery}`;
    if (searchQuery) {
      const response = fetchSearchRedirectionResult(searchQuery);
      response.then((data) => {
        if(data.url_path !== undefined){
          router.push(data.url_path);
        }else{
          router.push(url);

        }
      });
      response.catch((error) => {
        router.push(url);
      });
      setSearchOpen(false);
      updateSearchHistory(searchQuery);
      swanSearch(searchQuery);
    }
  };

  const updateSearchHistory = async (text) => {
    const searchHistory =
      JSON.parse(localStorage.getItem('searchHistory')) || [];
    localStorage.setItem(
      'searchHistory',
      JSON.stringify([text, ...searchHistory])
    );

    await handleSearchTextCleaned();
  };

  const handleClickOutside = (event) => {
    const input = document.getElementById('header-nav-search');
    const modal = document.getElementById('header-search-modal');

    if (modal && input) {
      if (!modal.contains(event?.target) && !input.contains(event?.target)) {
        setSearchOpen(false);
      }
    }
  };

  useEffect(() => {
    if (searchOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [searchOpen]);

  useEffect(() => {
    if (pathChanged.changed) {
      setSearchOpen(false);
    }
  }, [pathChanged]);

  return (
    <div className="col-span-6 w-full ps-5 2xl:col-span-6">
      <div className="relative z-30 flex max-h-[52px] w-full items-center md:max-w-[20.25rem] lg:max-w-[25.25rem] 2xl:max-w-[38.25rem]">
        <input
          onClick={() => setSearchOpen(true)}
          onChange={(e) => setSearchText(e?.target?.value)}
          onKeyDown={handleKeyDown}
          className={twMerge(
            'relative h-full max-h-[52px] w-full',
            'rounded-[6.25rem] border border-primary border-opacity-10 bg-white',
            'py-1.5 pe-1.5 pl-[22px] lg:h-[52px] rtl:pr-14',
            'text-sm leading-[1.43] text-[#999999]',
            'hover:border-2 hover:border-primary hover:outline-none hover:ring-0',
            'focus:border-2 focus:border-primary focus:outline-none focus:ring-0',
            clsx({
              'border-opacity-100': searchOpen
            })
          )}
          id="header-nav-search"
          data-testid="header-nav-search"
          value={searchText || ''}
        />

        {!searchText && (
          <span className="pointer-events-none absolute left-6 z-10 whitespace-nowrap text-sm leading-[1.43] text-[#999999]">
            {t('common.navbar.search')}{' '}
            {isSuccess && placeholders ? (
              <span
                key={currentIndex}
                className="inline-block transform-gpu animate-fade-in-up transition-transform"
              >
                "{placeholders[currentIndex]?.value}"
              </span>
            ) : (
              <span
                key={currentIndex}
                className="inline-block transform-gpu animate-fade-in-up transition-transform"
              >
                "{t('common.navbar.default_search_placeholder')}"
              </span>
            )}
          </span>
        )}

        <div
          onClick={() => handleSubmit()}
          className="absolute right-2 flex size-10 cursor-pointer items-center justify-center rounded-full bg-primary"
        >
          <Icon name="search-white" className="text-white" size={18} />
        </div>
        {searchOpen && (
          <Search
            setSearchOpen={setSearchOpen}
            data={trendings}
            searchText={searchText}
            setSearchText={setSearchText}
            doSearch={do_search}
          />
        )}
      </div>
    </div>
  );
}
