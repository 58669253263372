'use client';

import { useState } from 'react';
import { MenuItemType } from '@akinon/next/types';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { closeMobileMenu } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';

import { UserMenu } from './user-menu';
import {
  Button,
  CurrencySelect,
  Icon,
  LanguageSelect,
  Link
} from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';

interface MobileMenuProps {
  menu: MenuItemType[];
}

export default function MobileMenu(props: MobileMenuProps) {
  const { menu } = props;
  const dispatch = useAppDispatch();
  const [selectedSubMenu, setSelectedSubMenu] = useState<MenuItemType | null>(
    null
  );
  const { t, locale } = useLocalization();
  const isMobileMenuOpen = useAppSelector(
    (state) => state.header.isMobileMenuOpen
  );

  return (
    <>
      {/* MENU OVERLAY */}
      <div
        className={clsx(
          'invisible fixed left-0 top-0 z-30 h-screen w-screen bg-black bg-opacity-80 opacity-0 transition duration-500',
          {
            'scroll-lock !visible !opacity-100': isMobileMenuOpen
          }
        )}
        // TODO: Remove this after we have a better solution for clicking outside of the menu
        onClick={() => {
          dispatch(closeMobileMenu());
          setSelectedSubMenu(null);
        }}
      />
      {/* TODO: Add a way to close the menu when clicking outside of it */}
      <div
        className={clsx(
          'invisible fixed left-0 top-0 z-50 flex h-screen w-72 -translate-x-72 transform flex-col bg-white pt-4 opacity-0 transition duration-500',
          {
            '!visible translate-x-0 !opacity-100': isMobileMenuOpen
          }
        )}
      >
        {/* <UserMenu isMobile /> */}
        <div className="relative flex-1 overflow-x-hidden">
          <ul
            className={clsx('transform pt-4 transition duration-500', {
              '-translate-x-full': selectedSubMenu
            })}
          >
            {menu.map(
              (item, index) =>
                item.label != null && (
                  <li key={index} className="px-8 py-4 text-sm">
                    <Link
                      href={item.url}
                      onClick={(e) => {
                        if (item.children.length > 0) {
                          e.preventDefault();
                          setSelectedSubMenu(item);
                        }
                      }}
                      className="flex items-center justify-between"
                    >
                      <span>{item.label}</span>
                      <Icon name={locale.startsWith('ar') ? 'chevron-start':'chevron-end'} size={14} />
                    </Link>
                  </li>
                )
            )}
          </ul>
          <div
            className={clsx(
              'invisible absolute left-0 right-0 top-0 translate-x-full transform bg-white px-8 opacity-0 transition duration-500',
              {
                '!visible !translate-x-0 !opacity-100': selectedSubMenu
              }
            )}
          >
            <header className="mb-4 flex h-[61px] items-center justify-between border-b py-4">
              <Button
                appearance="ghost"
                onClick={() => setSelectedSubMenu(null)}
                className="flex items-center gap-2 !p-0 text-xs font-semibold underline"
              >
                <Icon name={locale.startsWith('ar') ? 'chevron-end':'chevron-start'} size={12} className="shrink-0" />
                {t('common.mobile_menu.back')}
              </Button>
              <span className="text-sm">{selectedSubMenu?.label}</span>
            </header>
            <div className="flex flex-col">
              {selectedSubMenu?.children.map((child, index) => (
                <div key={index} className="flex flex-col">
                  <Link
                    onClick={() => dispatch(closeMobileMenu())}
                    href={child.url}
                    className="py-4 text-xs font-bold"
                  >
                    {child.label}
                  </Link>

                  <ul>
                    {child.children.map((subChild, index) => (
                      <li key={index} className="">
                        <Link
                          onClick={() => dispatch(closeMobileMenu())}
                          href={subChild.url}
                          className="flex items-center justify-between py-4 text-sm"
                        >
                          <span>{subChild.label}</span>
                          <Icon name={locale.startsWith('ar') ? 'chevron-start':'chevron-end'} size={14} />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
