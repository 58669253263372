'use client';

import { useAppDispatch } from '@akinon/next/redux/hooks';
import { toggleMobileMenu } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';
import { useLocalization } from '@akinon/next/hooks';
import { PwaBackButton } from './pwa-back-button';
import { Button, Icon } from '@theme/components';

export default function MobileHamburgerButton() {
  const { locale } = useLocalization();
  const dispatch = useAppDispatch();
  return (
    <div className={clsx('row-start-2 flex sm:hidden me-[5px]', locale === 'ar-qa' ? 'translate-x-2' : '-translate-x-2')}>
      <PwaBackButton />
      <Button className='bg-gray-25 w-8 py-1.5 px-2 border-none rounded-md text-[#51526c] ' onClick={() => dispatch(toggleMobileMenu())}>
        <Icon name="hamburger" size={16}  />
      </Button>
    </div>
  );
}
