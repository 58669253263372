'use client';

import { MenuItemType } from '@akinon/next/types';
import { motion } from 'framer-motion';
import { useRef, useCallback, useState, useEffect } from 'react';
import NestedMenu from './NestedMenu';
import { Icon, Link } from '@akinon/next/components';
import { useLocalization } from '@akinon/next/hooks';
import SearchBar from '../search/desktop-search-bar';
import { NavigationExtraLinkType } from '@theme/types';
import { setOpenedMenu } from '@akinon/next/redux/reducers/header';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { getCookie } from '@akinon/next/utils';
interface NavbarProps {
  menu: MenuItemType[];
  navigationExtraLinkData?: NavigationExtraLinkType;
}

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
};

const localeShowOnMap = {
  'en-ae': 'show_on_ue',
  'ar-ae': 'show_on_ue',
  'en-qa': 'show_on',
  'ar-qa': 'show_on',
  'en-sa': 'show_on_sa',
  'ar-sa': 'show_on_sa',
  'en-om': 'show_on_om',
  'ar-om': 'show_on_om',
  'en-kw': 'show_on_kw',
  'ar-kw': 'show_on_kw',
  'en-bh': 'show_on_bh',
  'ar-bh': 'show_on_bh'
};

export default function Navbar(props: NavbarProps) {
  const { menu, navigationExtraLinkData } = props;
  const { t, locale } = useLocalization();
  const openedMenu = useAppSelector((state) => state.header.openedMenu);
  const dispatch = useAppDispatch();

  const navRef = useRef<HTMLDivElement>(null);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);
  const [activeTheme, setActiveTheme] = useState('');
  const delay = 200; // Delay in ms for hover intent
  let visibleItemCount = 0;

  // Function to handle mouse enter with hover intent
  const handleMouseEnter = useCallback(
    (pk: string) => {
      if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
      }
      // Set a timeout to delay menu opening
      hoverTimeout.current = setTimeout(() => {
        dispatch(setOpenedMenu(pk));
      }, delay);
    },
    [dispatch]
  );

  // Function to handle mouse leave for the entire nav element
  const handleNavMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      const relatedTarget = event.relatedTarget as Node | null;

      if (
        navRef?.current &&
        relatedTarget && // Ensure relatedTarget is not null
        !navRef?.current?.contains(relatedTarget) // Check if the new target is outside the nav
      ) {
        if (hoverTimeout.current) {
          clearTimeout(hoverTimeout.current);
        }
        dispatch(setOpenedMenu(null));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    setActiveTheme(getCookie('theme') || 'scheduled');
  }, []);

  const shouldShowItem = {
    both: true,
    [activeTheme]: true // Show the item if it is set to the active theme. tested against 'scheduled' and 'express' themes
  };

  const filteredMenu = menu.filter((item) => {
    const showOnKey = localeShowOnMap[locale];
    const show_on = item.extra_context.attributes[showOnKey]?.value;
    return shouldShowItem[show_on];
  });

  return (
    <div className="hidden gap-4 border border-primary/10 bg-primary/10 px-[25px] py-2 lg:flex lg:grid-cols-11 lg:px-[50px]">
      <nav className="navbar relative col-span-7 hidden gap-4 lg:flex 2xl:col-span-5">
        <div
          className="relative hidden gap-x-[18px] lg:flex"
          ref={navRef}
          onMouseLeave={handleNavMouseLeave}
        >
          <div className="inline-flex items-center gap-x-[8px] 2xl:gap-x-[18px]">
            <button
              onMouseEnter={() => handleMouseEnter('categories')}
              className={twMerge(
                clsx(
                  'categories-button flex-center relative gap-1.5 rounded-md p-1.5 text-sm transition-colors hover:bg-primary hover:text-white',
                  openedMenu === 'categories' && 'bg-primary text-white'
                )
              )}
            >
              <Icon size={20} name="bottom-nav-categories-stroke" />
              <span>{t('common.navbar.categories')}</span>
              {openedMenu === 'categories' && (
                <div
                  data-testid="nav-item-hover-helper"
                  className="absolute inset-x-0 -bottom-4 h-4 bg-transparent"
                ></div>
              )}
            </button>
            {filteredMenu.slice(0, 4).map((item) => {
              return (
                <div key={item.pk} className="relative flex">
                  <Link
                    href={item.url}
                    className={twMerge(
                      clsx(
                        'category-item rounded-md p-1.5 text-sm transition-colors hover:bg-primary hover:text-white',
                        openedMenu === item.pk && 'bg-primary text-white'
                      )
                    )}
                    onClick={() => dispatch(setOpenedMenu(null))}
                    onMouseEnter={() => handleMouseEnter(item.pk)}
                  >
                    <span className="whitespace-nowrap">{item.label}</span>
                    {openedMenu === item.pk && (
                      <div
                        data-testid="nav-item-hover-helper"
                        className="absolute inset-x-0 -bottom-4 h-4 bg-transparent"
                      ></div>
                    )}
                  </Link>
                  {openedMenu === item.pk && item.children?.length && (
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      variants={containerVariants}
                      transition={{
                        type: 'tween',
                        duration: 0.3,
                        ease: 'easeOut'
                      }}
                      key={item.pk}
                      className={
                        'shadow-lg absolute start-0 top-[calc(8px+100%)] z-50 rounded-[12px] border border-gray-380 bg-white'
                      }
                    >
                      <NestedMenu items={item.children} level={2} />
                    </motion.div>
                  )}
                </div>
              );
            })}
          </div>
          {openedMenu === 'categories' && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={containerVariants}
              transition={{ type: 'tween', duration: 0.3, ease: 'easeOut' }}
              key={'categories'}
              className={
                'shadow-lg absolute start-0 top-[calc(100%)] z-50 overflow-hidden rounded-xl border border-gray-380 bg-white'
              }
            >
              <NestedMenu items={filteredMenu} level={1} />
            </motion.div>
          )}
        </div>

        {navigationExtraLinkData && (
          <Link
            href={navigationExtraLinkData?.url?.value}
            target={
              navigationExtraLinkData?.is_target_blank?.value !== 'false'
                ? '_blank'
                : '_self'
            }
            className={twMerge(
              clsx('flex items-center p-1.5 text-sm font-bold text-primary')
            )}
          >
            {navigationExtraLinkData?.label.value}
          </Link>
        )}
      </nav>

      <SearchBar />
    </div>
  );
}
