'use client';

import { FooterContactType } from '@theme/views/footer/types';
import { useLocalization } from '@akinon/next/hooks';
import { Link } from '@theme/components';

type FooterContactProps = {
  data: FooterContactType;
};

const getRedirectUrl = (item: any) => {
  const { title, value } = item?.value || {};

  if (item?.value?.redirect_url) {
    return item.value.redirect_url;
  }

  switch (title?.toLowerCase()) {
    case 'whatsapp':
      return `https://wa.me/${value.replace(/[^0-9]/g, '')}`;
    case 'phone':
      return `tel:${value}`;
    case 'email':
      return `mailto:${value}`;
    default:
      return '#';
  }
};

export default function FooterContact(props: FooterContactProps) {
  const { data } = props;
  const { t } = useLocalization();

  return (
    <section className="mt-10">
      <span className="text-sm font-bold">
        {t('common.footer.get_in_touch')}
      </span>
      <div className="flex flex-wrap">
        {data?.attributes?.footer_contact_items?.map((item, i) => (
          <div
            className={`mt-5 grid ${i === data.attributes.footer_contact_items.length - 1 ? 'lg:me-0' : 'me-9 lg:me-[42.6px]'}`}
            key={i}
          >
            <span className="mb-[6px] text-xs">{item?.value?.title}</span>
            <Link href={getRedirectUrl(item)} target="_blank">
              <span className="text-sm font-normal">{item?.value?.value}</span>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
}
