'use client';

import { useEffect, useRef, useState } from 'react';
import { Button, Icon, Image } from '@akinon/next/components';
import { countries } from 'countries';
import { useLocalization } from '@akinon/next/hooks';
import { removeCookie } from '@akinon/next/utils';
import { useAddress } from '@theme/hooks';
import { useOnClickOutside } from '@akinon/next/hooks';

interface CountrySelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
}

export const CountrySelect = (props: CountrySelectProps) => {
  const { className } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { locale, setLocale } = useLocalization();
  const [languageCode, countryCode] = locale.split('-');
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const { updateAddressAfterLocaleChange, addressList } = useAddress();

  const handleChange = async (e) => {
    removeCookie('userLocation');
    removeCookie('selectedAddress');
    removeCookie('erp_code')
    const selectedCountryCode = e.currentTarget.value;

    const redirectLink = countries.find(
      (country) => country.value === selectedCountryCode
    ).link;

    const hasHttp = redirectLink.includes('http');

    if (hasHttp) {
      window.location = redirectLink as any;
    } else {
      localStorage.setItem('localeChanged', 'true');
      localStorage.setItem(
        'newLocale',
        `${languageCode}-${selectedCountryCode}`
      );
      setLocale(`${languageCode}-${selectedCountryCode}`);
    }
  };

  useEffect(() => {
    const localeChanged = localStorage.getItem('localeChanged') === 'true';
    const newLocale = localStorage.getItem('newLocale');

    if (localeChanged && newLocale && newLocale === locale) {
      const checkAddressesAndUpdate = async () => {
        removeCookie('userLocation');
        removeCookie('selectedAddress');
        removeCookie('erp_code')
        await updateAddressAfterLocaleChange();
        localStorage.removeItem('localeChanged');
        localStorage.removeItem('newLocale');
      };

      checkAddressesAndUpdate();
    }
  }, [locale, updateAddressAfterLocaleChange, addressList]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectedOption = countries.find(
    (option) => option.value === countryCode
  );
  return (
    <div
      className="relative ml-2 flex h-11 w-11 items-center justify-center bg-[#fafbfc] px-0.5 lg:ml-4 lg:h-11 lg:w-11 lg:bg-transparent lg:px-0 rtl:!ml-0 rtl:mr-2 rtl:lg:mr-4"
      ref={dropdownRef}
    >
      <div
        className="flex h-full w-full cursor-pointer items-center"
        onClick={toggleDropdown}
      >
        {selectedOption && (
          <>
            <Image
              src={selectedOption.imageUrl}
              alt="lulu"
              width={40}
              className="hidden lg:flex"
              imageClassName="!w-[40px] !h-[25px]"
              height={20}
              loading="eager"
              fetchPriority="high"
            />

            <Image
              src={selectedOption.imageUrl}
              alt="lulu"
              width={25}
              className="lg:hidden"
              height={13}
              loading="eager"
              fetchPriority="high"
            />
          </>
        )}

        <Icon name="chevron-down" className="" size={20} />
      </div>

      {isOpen && (
        <div className="absolute right-0 top-10 z-50 w-[173px] rounded-xl border bg-white p-3">
          {countries.map((option) => (
            <Button
              key={option.value}
              value={option.value}
              onClick={(e: any) => handleChange(e)}
              className="flex w-full items-center gap-3 rounded-lg border-white bg-white px-2 py-3 text-base font-medium text-black hover:border-white hover:bg-gray-400 hover:text-black"
            >
              <Image
                src={option.imageUrl}
                alt="Country Flag"
                width={29}
                height={18}
                className="!h-[18px] !w-[29px]"
              />
              {option.en.label.toLocaleUpperCase('en-US')}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};
