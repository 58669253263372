import { useAppDispatch } from '@akinon/next/redux/hooks';
import { setOpenedMenu } from '@akinon/next/redux/reducers/header';
import { useCallback, useState } from 'react';
import { useLocalization } from '@akinon/next/hooks';
import { MenuItemType } from '@akinon/next/types';
import { Icon, Link } from '@theme/components';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

interface NestedMenuProps {
  items: MenuItemType[];
  level?: number;
}

const motionAttributes = {
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  variants: {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  },
  transition: { type: 'tween', duration: 0.3, ease: 'easeInOut' }
};

const NestedMenu: React.FC<NestedMenuProps> = ({ items, level = 0 }) => {
  const [expandedItem, setExpandedItem] = useState<MenuItemType>();
  const [hoveredItemPK, setHoveredItemPK] = useState<string | null>(null);
  const { locale } = useLocalization();
  const dispatch = useAppDispatch();
  const isArabicLayout = locale.indexOf('ar') > -1;

  // Toggle the expanded state of a menu item
  const handleToggle = useCallback(
    (e: React.MouseEvent, item: MenuItemType) => {
      e.stopPropagation();
      setExpandedItem((prevItem) =>
        prevItem?.pk === item.pk ? undefined : item
      );
    },
    []
  );

  const handleMouseLeave = useCallback(() => setHoveredItemPK(null), []);

  const getContainerClasses = () =>
    twMerge(
      clsx('flex h-full rounded-xl', {
        'gap-x-2.5 bg-white p-2.5': level === 1,
        'gap-x-5 bg-gray-50 pb-[22px] pl-3.5 pr-1 pt-3.5 text-sm': level === 2,
        'text-sm': level === 3
      })
    );

  const getMenuClasses = () =>
    twMerge(
      clsx('menu', {
        'w-64 space-y-1': level === 1,
        'w-[156px]': level === 2,
        'flex w-[534px] flex-wrap gap-x-5 gap-y-10': level === 3 && items.some(item => item.children.length > 0),
      })
    );

  const getMenuItemClasses = () =>
    twMerge(
      clsx('menu-item', {
        'w-[164px] space-y-3': level === 3,
        'py-1 px-2.5': level === 3 && !items.some(item => item.children.length > 0),
        '': level !== 3
      })
    );

  const getLinkClasses = (item: MenuItemType) =>
    twMerge(
      clsx(
        'flex items-center text-start transition-colors',
        {
          'gap-3.5 rounded-[10px] border border-transparent px-2.5 py-1.5 font-medium hover:border-primary hover:bg-primary/10':
            level === 1,
          'w-full justify-between px-2.5 py-1 font-medium': level === 2,
          'font-semibold': level === 3 && item.children.length > 0
        },
        expandedItem?.pk === item.pk && {
          'border-primary bg-primary/10': level === 1,
          'text-primary': level === 2,
          '': level === 3
        }
      )
    );

  return (
    <div className={getContainerClasses()}>
      <div className={getMenuClasses()}>
        {items.map((item) => (
          <div
            key={item.pk}
            className={getMenuItemClasses()}
            data-level={level}
          >
            <button
              className="w-full"
              onMouseEnter={(e) => handleToggle(e, item)}
              onMouseLeave={handleMouseLeave}
              onClick={() => dispatch(setOpenedMenu(null))}
            >
              <Link href={item.url} className={getLinkClasses(item)}>
                {level === 1 && (
                  <div className="flex-center h-[30px] w-[30px] rounded-md bg-gray-100">
                    {item.extra_context.attributes?.icon && (
                      <img
                        src={item.extra_context.attributes.icon?.kwargs.url}
                        alt={item.label}
                      />
                    )}
                  </div>
                )}
                {item.label}
                {level === 2 && (
                  <Icon
                    className={clsx(
                      'opacity-0 transition-opacity',
                      (expandedItem?.pk === item.pk ||
                        hoveredItemPK === item.pk) &&
                      'opacity-100'
                    )}
                    size={10}
                    name={isArabicLayout ? 'chevron-start' : 'chevron-end'}
                  />
                )}
              </Link>
            </button>

            {level === 3 && item.children.length > 0 && (
              <div className="space-y-1 text-gray-600">
                {item.children?.slice(0, 4).map((child) => (
                  <Link
                    href={child.url}
                    key={child.pk}
                    className="menu-item block w-fit underline decoration-transparent underline-offset-[3px] transition-colors hover:text-black hover:decoration-gray-600"
                  >
                    {child.label}
                  </Link>
                ))}
                {item.children?.length > 4 && (
                  <Link
                    href={item.url}
                    className="menu-item block w-max text-primary underline decoration-black underline-offset-[3px] transition-colors hover:decoration-primary-700"
                  >
                    View all
                  </Link>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {level < 3 && (
        <>
          {expandedItem && expandedItem.children.length && (
            <motion.div
              {...motionAttributes}
              key={expandedItem.pk}
              className="submenu"
              style={{
                maxHeight: '560px',
                overflowY: 'auto',
                scrollbarColor: '#d7d7d7 #f7f7f7',
              }}
            >
              <NestedMenu items={expandedItem.children} level={level + 1} />
            </motion.div>
          )}
        </>
      )}
    </div>
  );
};

export default NestedMenu;
