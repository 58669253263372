'use client';

import {
  ReactNode,
  useMemo,
  useRef,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useLazyGetBasketDetailQuery } from '@akinon/next/data/client/basket';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import {
  closeMiniBasket,
  toggleMiniBasket
} from '@akinon/next/redux/reducers/root';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import { ROUTES } from '@theme/routes';
import MiniBasket from './mini-basket';
import { Badge, Icon, Link } from '@theme/components';
import {
  useLocalization,
  useOnClickOutside,
  useRouter
} from '@akinon/next/hooks';
import { useWindowSize } from '@theme/hooks/use-window-size';
import {
  useGetCollectionsOOSQuery,
  useSetCollectionItemsMutation,
  useSetCollectionOOSMutation
} from '@akinon/next/data/client/wishlist';
import { getCollectionName } from '@theme/utils/get-collection-name';
import { getCookie } from '@akinon/next/utils';

interface MenuItem {
  label: string;
  url?: string;
  action?: () => void;
  icon: string;
  className?: string;
  badge?: ReactNode;
  miniBasket?: ReactNode;
  dataTestId?: string;
}

export const ActionMenu = () => {
  const [isHydrated, setIsHydrated] = useState(false);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [getBasketDetail, { data: basketData }] = useLazyGetBasketDetailQuery();
  const { locale } = useLocalization();
  const pathname = usePathname();
  const [setCollectionOOS] = useSetCollectionOOSMutation();
  const [setCollectionItems] = useSetCollectionItemsMutation();
  const theme = getCookie('theme');
  const collectionName = useMemo(
    () => getCollectionName(locale, theme),
    [locale, theme]
  );

  const { data: collectionList, isSuccess } = useGetCollectionsOOSQuery(
    {
      search: collectionName
    },
    {
      skip: !basketData || basketData.unavailable_basket_products.length === 0
    }
  );

  useEffect(() => {
    if (
      basketData &&
      basketData.unavailable_basket_products.length > 0 &&
      isSuccess
    ) {
      if (collectionList?.count > 0) {
        const res = collectionList.results[0];
        basketData.unavailable_basket_products.map((product) => {
          setCollectionItems({
            usercollection_id: res.pk,
            product_id: product.pk
          }).unwrap();
        });

        return;
      }
      setCollectionOOS({
        name: collectionName,
        status: 'private'
      })
        .unwrap()
        .then(async (res) => {
          for (const product of basketData.unavailable_basket_products) {
            await setCollectionItems({
              usercollection_id: res.pk,
              product_id: product.pk
            }).unwrap();

            await new Promise((resolve) => setTimeout(resolve, 300));
          }
        });
    }
  }, [basketData, collectionList, isSuccess, collectionName]);

  const hiddenItem = basketData?.basketitem_set.find(
    (item) => item.product.attributes.misc_product_hide === 'hide'
  );

  const totalQuantity = useMemo(
    () =>
      hiddenItem
        ? basketData?.total_quantity - 1
        : basketData?.total_quantity ?? 0,
    [basketData]
  );

  const { open: miniBasketOpen } = useAppSelector(
    (state) => state.root.miniBasket
  );
  const miniBasketRef = useRef(null);
  const closeMiniBasketCb = useCallback(() => {
    if (miniBasketOpen) dispatch(closeMiniBasket());
  }, [miniBasketOpen, dispatch]);
  useOnClickOutside(miniBasketRef, closeMiniBasketCb);

  const { width } = useWindowSize();
  const isMobile = width ? width < 768 : false;

  const MenuItems: MenuItem[] = [
    {
      label: 'Basket',
      action() {
        if (isMobile) {
          router.push(ROUTES.BASKET);
        } else {
          if (!pathname.includes(ROUTES.BASKET)) dispatch(toggleMiniBasket());
        }
      },
      icon: 'bottom-nav-cart-outlined',
      dataTestId: 'header-basket',
      badge: (
        <Badge
          className={clsx(
            'w-4',
            totalQuantity === 0
              ? 'bg-[#ec1d23] text-white'
              : 'bg-[#ec1d23] text-white',
            totalQuantity > 99 && 'w-6'
          )}
        >
          {totalQuantity}
        </Badge>
      ),
      miniBasket: <MiniBasket />
    }
  ];

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  useEffect(() => {
    if (theme) {
      getBasketDetail({ namespace: theme });
    }
  }, [theme]);

  const isInBasket = pathname.includes(ROUTES.BASKET);

  return (
    isHydrated && (
      <ul className="flex h-10 w-10 items-center rounded-md bg-[#fafbfc] px-2 py-1.5 lg:bg-transparent">
        {MenuItems.map((menu, index) => (
          <li
            key={index}
            className={clsx('relative flex items-center', menu.className)}
            ref={menu.miniBasket ? miniBasketRef : null}
          >
            {menu.action ? (
              <button onClick={menu.action} data-testid={menu.dataTestId}>
                <Icon name={menu.icon} size={24} />
                {menu.badge}
              </button>
            ) : (
              <Link
                href={menu.url ?? '#'}
                passHref={true}
                onClick={(event) => {
                  if (menu.action) {
                    event.preventDefault();
                    menu.action();
                  }
                }}
                data-testid={menu.dataTestId}
              >
                <Icon name={menu.icon} size={24} />
                {menu.badge}
              </Link>
            )}
            {!isMobile && !isInBasket && menu?.miniBasket}
          </li>
        ))}
      </ul>
    )
  );
};
