'use client';

import { Image } from '@akinon/next/components';
import { useLocalization } from '@akinon/next/hooks';
import { Link } from '@theme/components';

export default function HeaderLogo() {
  const { locale } = useLocalization();
  return (
    <Link href={`${locale === 'ar-qa' ? '/ar-qa/' : '/'}`}>
      <Image
        className="block min-w-[39px] lg:hidden"
        src="/akn-logo-mark.svg"
        alt="Lulu Logo"
        width={39}
        height={34}
        aspectRatio={39 / 34}
        loading="eager"
        fetchPriority="high"
      />
      <Image
        className="hidden lg:block"
        src="/akn-logo-english.svg"
        alt="Lulu Logo"
        width={138}
        height={43}
        aspectRatio={150 / 60}
        loading="eager"
        fetchPriority="high"
      />
    </Link>
  );
}
