'use client';

import { Image } from '@akinon/next/components';
import {
  useGetAllBasketsQuery,
  useSelectMainBasketMutation
} from '@akinon/next/data/client/basket';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import { useLocalization, useOnClickOutside } from '@akinon/next/hooks';
import { getCookie } from '@akinon/next/utils';
import { Icon } from '@theme/components';
import { useWindowSize } from '@theme/hooks/use-window-size';
import { ROUTES } from '@theme/routes';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

const countryMap = {
  qa: 'Qatar',
  bh: 'Bahrain',
  sa: 'KSA',
  om: 'Oman',
  ae: 'UAE',
  kw: 'Kuwait'
};

export default function ModeSwitcherButton({ quickControlWidget }) {
  const { t, locale } = useLocalization();
  const { width } = useWindowSize();
  const pathname = usePathname();

  const MODE = {
    QUICK: t('common.mode_switcher.quick'),
    SCHEDULED: t('common.mode_switcher.scheduled')
  };

  const {
    data: deliveryModeData,
    isLoading: deliveryModeIsLoading,
    isSuccess: deliveryModeIsSuccess
  } = useGetWidgetQuery('delivery-mode');

  const countryCode = locale.split('-')[1];

  const deliveryMode =
    deliveryModeIsSuccess &&
    (deliveryModeData?.attributes as any)?.delivery_mode?.find(
      (mode) => mode?.value?.country === countryMap[countryCode]
    );

  const quickDelivery = Boolean(deliveryMode?.value?.quick === 'True');
  const scheduledDelivery = Boolean(deliveryMode?.value?.scheduled === 'True');

  const [theme, setTheme] = useState('default');
  const [isVisible, setIsVisible] = useState(false);
  const infoModalRef = useRef(null);
  const closeInfoModal = () => setIsVisible(false);
  useOnClickOutside(infoModalRef, closeInfoModal);
  const [selectMainBasket] = useSelectMainBasketMutation();
  const { data: allBaskets, isSuccess: isAllBasketsSuccess } =
    useGetAllBasketsQuery();
  const [availabilityData, setAvailabilityData] = useState(null);
  const [isVisibleAvailability, setIsVisibleAvailability] = useState(false);
  const erp_code = getCookie('erp_code');

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const switchModeHandler = (theme) => {
    const date = new Date();
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    document.cookie =
      'theme' +
      '=' +
      theme +
      '; expires=' +
      date.toString() +
      '; path=/; SameSite=None; Secure';
    const selectedBasket = allBaskets?.find(
      (basket) => basket.namespace === getCookie('theme')
    );
    if (selectedBasket) {
      selectMainBasket({ pk: selectedBasket.pk });
    }
    isAllBasketsSuccess && window.location.reload();
  };

  useEffect(() => {
    const root = document.body;
    root.classList.remove('default');
    if (theme == 'scheduled') {
      root.classList.add('scheduled');
      root.classList.remove('express');
    } else if (theme == 'express') {
      root.classList.add('express');
      root.classList.remove('scheduled');
    }
  }, [theme]);

  const fetchScheduledData = async () => {
    if (!erp_code) return;

    const data = {
      scheduled: true,
      store: erp_code
    };

    try {
      const response = await fetch('/api/instaleap/dummy_availability', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const result = await response.json();

      const suffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
          case 1:
            return 'st';
          case 2:
            return 'nd';
          case 3:
            return 'rd';
          default:
            return 'th';
        }
      };

      const formatDate = (date) => {
        const day = date.getDate();
        return `${day}${suffix(day)}, ${date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          hour12: true
        })}`;
      };

      if (result && result[0] && result[0].to) {
        const date = new Date(result[0].to);
        date.setMinutes(date.getMinutes() + 1);

        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        if (date.toDateString() === today.toDateString()) {
          setAvailabilityData(t('checkout.slot_select.days.today'));
        } else if (date.toDateString() === tomorrow.toDateString()) {
          setAvailabilityData(t('checkout.slot_select.days.tomorrow'));
        } else {
          setAvailabilityData(formatDate(date));
        }
      } else {
        const now = new Date();
        now.setDate(now.getDate() + 2);
        const day = now.getDate();

        setAvailabilityData(`${day}${suffix(day)}, 3pm-5pm`);
      }
    } catch (error) {
      console.error('Error fetching availability data:', error);
    }
  };

  useEffect(() => {
    if (!deliveryModeIsLoading && deliveryModeIsSuccess) {
      if (scheduledDelivery) fetchScheduledData();

      setThemeBasedOnDelivery(quickDelivery, scheduledDelivery);

      setIsVisibleAvailability(true);
    }
  }, [deliveryModeIsLoading, deliveryModeIsSuccess, erp_code]);

  const setThemeBasedOnDelivery = (quick, scheduled) => {
    let tempTheme = 'default';
    if (quick && !scheduled) {
      tempTheme = 'express';
    } else if (scheduled && !quick) {
      tempTheme = 'scheduled';
    } else if (!quick && !scheduled) {
      tempTheme = 'scheduled';
    } else {
      tempTheme = getCookie('theme');
    }

    const shownTooltip = localStorage.getItem('shownTooltip');
    const date = new Date();
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    if (tempTheme) {
      setTheme(tempTheme);
      document.cookie =
        'theme' +
        '=' +
        tempTheme +
        '; expires=' +
        date.toString() +
        '; path=/; SameSite=None; Secure';
    } else {
      setTheme('scheduled');
      document.cookie =
        'theme' +
        '=' +
        'scheduled' +
        '; expires=' +
        date.toString() +
        '; path=/; SameSite=None; Secure';
    }
    if (!shownTooltip && width >= 1024 && tempTheme === 'scheduled') {
      setIsVisible(true);
      localStorage.setItem('shownTooltip', 'true');
    }
  };

  return (
    <>
      {/* Switcher Area */}

      {deliveryModeIsSuccess && (quickDelivery || scheduledDelivery) && (
        <div
          className={clsx(
            'relative flex h-[54px] rounded-[120px] border border-[#f0f0f0] bg-[#f2f3f2] p-1.5 lg:h-[60px]',
            {
              'w-full 2xl:min-w-[532px]': quickDelivery && scheduledDelivery,
              'w-full max-w-[350px] 2xl:min-w-[265px]':
                quickDelivery !== scheduledDelivery // If only one is true
            }
          )}
        >
          {scheduledDelivery && (
            <div
              onClick={() => switchModeHandler('scheduled')}
              className={clsx(
                'group flex w-1/2 cursor-pointer items-center justify-center rounded-[120px] px-4 lg:justify-between lg:hover:bg-[#04a550] lg:hover:bg-opacity-50 lg:hover:text-white',
                {
                  'w-1/2': quickDelivery,
                  'w-full': !quickDelivery,
                  'bg-[#f2f3f2] text-[#04a550]': theme === 'scheduled',
                  'text-[#04a550]': theme !== 'scheduled'
                }
              )}
            >
              <div
                className={clsx(
                  'flex items-center gap-3',
                  isVisibleAvailability && availabilityData
                    ? 'justify-start'
                    : 'flex-1 justify-center'
                )}
              >
                <Icon
                  name="scheduled-white"
                  className="group-hover:text-white"
                  size={24}
                />
                <span className="text-sm font-semibold">{MODE.SCHEDULED}</span>
              </div>

              {isVisibleAvailability && availabilityData && (
                <div
                  className={clsx(
                    'hidden flex-row gap-1 text-right text-xs leading-normal tracking-normal text-[#04a550] group-hover:text-white lg:flex 2xl:gap-3'
                  )}
                >
                  <span>{t('common.mode_switcher.get_it_by')}</span>
                  <span>{availabilityData}</span>
                </div>
              )}
            </div>
          )}

          {quickDelivery && (
            <div
              onClick={() => switchModeHandler('express')}
              className={clsx(
                'group flex w-1/2 cursor-pointer items-center justify-center rounded-[120px] px-4 lg:justify-between lg:hover:bg-[#bb2027] lg:hover:bg-opacity-50',
                {
                  'w-1/2': scheduledDelivery,
                  'w-full': !scheduledDelivery,
                  'bg-[#bb2027] text-gray-25': theme === 'express',
                  'text-[#bb2027]': theme !== 'express'
                }
              )}
            >
              <div className="flex items-center gap-3">
                <Image
                  src={
                    theme === 'express'
                      ? '/akn-quick-icon-white.svg'
                      : '/akn-quick-icon-red.svg'
                  }
                  width={30}
                  height={21}
                  loading="eager"
                  alt="Quick"
                  aspectRatio={20 / 20}
                  className={clsx('group-hover:text-gray-25', {
                    'text-[#bb2027]': theme !== 'express',
                    'text-gray-25': theme === 'express'
                  })}
                />
                {/* <Icon
              name="track-order"
              size={20}
              className={clsx('group-hover:text-gray-25', {
                'text-[#bb2027]': theme !== 'express',
                'text-gray-25': theme === 'express'
              })}
            /> */}
                <span
                  className={clsx(
                    'text-sm font-semibold lg:group-hover:text-gray-25',
                    {
                      'text-gray-25': theme === 'express',
                      'text-[#bb2027]': theme !== 'express'
                    }
                  )}
                >
                  {MODE.QUICK}
                </span>
              </div>
              <div className="hidden flex-col text-right text-xs leading-normal tracking-normal 2xl:flex">
                <span>
                  {quickControlWidget
                    ? quickControlWidget?.attributes[`country_${countryCode}`]
                        ?.value.quick_text
                    : t('common.mode_switcher.get_it_in')}
                </span>
                {isVisibleAvailability && (
                  <span>
                    {quickControlWidget &&
                      quickControlWidget?.attributes[`country_${countryCode}`]
                        ?.value.quick_time}
                  </span>
                )}
              </div>
            </div>
          )}

          {quickDelivery && scheduledDelivery && (
            <AnimatePresence>
              {isVisible && theme === 'scheduled' && (
                <>
                  <motion.div
                    ref={infoModalRef}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="absolute right-[-240px] z-50 hidden -translate-y-1/2 lg:flex"
                    style={{ top: 'calc(50% - 9px)' }}
                  >
                    <div
                      className="relative h-[78px] w-[232px] rounded-lg"
                      style={{ boxShadow: '0px 8px 16px -2px #1B212C1F' }}
                    >
                      <div className="absolute-center-y -left-2">
                        <Icon
                          name="arrow-left-fill"
                          size={16}
                          className="text-white"
                        />
                      </div>
                      <div className="flex flex-col gap-1.5 rounded-lg bg-white p-[14px]">
                        <p className="text-sm text-black">
                          {t('common.header.quick_bubble_need_your_order')}
                        </p>
                        <p className="text-base font-bold text-[#bb2027]">
                          {t('common.header.quick_bubble_try_express_delivery')}
                        </p>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="fixed inset-0 z-40 hidden bg-black bg-opacity-10 lg:flex"
                  ></motion.div>
                </>
              )}
            </AnimatePresence>
          )}
        </div>
      )}
      {/* Mobile Area */}
      {isVisibleAvailability && (
        <div className="mt-[7px] flex w-full items-center text-black lg:hidden">
          {scheduledDelivery && availabilityData && (
            <div
              className={clsx('flex flex-col text-center', {
                'w-full': !quickDelivery,
                'w-1/2': quickDelivery
              })}
            >
              <span className="text-xs font-light leading-[1.67] tracking-normal">
                {t('common.mode_switcher.get_it_by')}
              </span>
              <span className="text-sm font-normal leading-[1.43] tracking-normal">
                {availabilityData}
              </span>
            </div>
          )}
          {quickDelivery && scheduledDelivery && (
            <div className="h-[37px] w-[2px] bg-gray"></div>
          )}
          {quickDelivery && (
            <div
              className={clsx('flex flex-col text-center', {
                'w-full': !scheduledDelivery,
                'w-1/2': scheduledDelivery
              })}
            >
              <span className="text-xs font-light leading-[1.67] tracking-normal">
                {t('common.mode_switcher.get_it_in')}
              </span>
              <span className="text-sm font-normal leading-[1.43] tracking-normal">
                60 min
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
}
